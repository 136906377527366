import React from 'react';
import './TextStyles.css';
import './Image.css'


class TitleComponent extends React.Component<{title: string}>{

  render() {
    return (
      <div className="banner">
      <div className="title-container">
          <h1 className="title">Explainability Perspectives on a Vision Transformer:<br/>From Global Architecture to Single Neuron</h1>
          <p className="subtitle">Anne Marx<sup>1,*</sup>, Yumi Kim<sup>1,*</sup>, Luca Sichi<sup>1,*</sup>, Diego Arapovic<sup>1,*</sup>, Javier Sanguino<sup>2</sup>, Rita Sevastjanova<sup>2</sup>, Mennatallah El-Assady<sup>2</sup><br/>
          Affiliations: <sup>1</sup> ETH Zurich, <sup>2</sup> IVIA Lab, ETH Zurich<br/>
          <sup>*</sup> Main contribution <br/>          
          </p>
      </div>
    </div>
    );
  }

}
export default TitleComponent;